<template>
    <aside class="menu">
        <p class="menu-label pt-2">
            <span class="icon-text ml-2">
                <span class="sg-brand-text has-text-black has-text-weight-semibold is-size-6">
                    Shamrock Guitars
                </span>
                <span class="icon ml-1">
                    <img src="logo.png" alt="Shamrock Guitars logo">
                </span>
            </span>
        </p>
        <ul class="menu-list">
            <li><a href="#/">Home</a></li>
            <!-- <li><a href="#/about">About</a></li> -->
            <!-- <li><a href="#/testimonials">Testimonials</a></li> -->
            <li><a href="#/contact">Contact Us</a></li>
            <!-- <li><a href="#/gallery">Gallery</a></li> -->
        </ul>
    </aside>
</template>

<script>
export default {
    name: 'SideNav',
    props: {
        activePage: String
    }
}
</script>

<style scoped>
.menu-list a {
    background-color: #C5D6CD;
}

/* @font-face {
    font-family: 'Wedding Day';
    src: url('../assets/Weddingday.ttf')
}

.sg-brand-text {
    font-family: 'Wedding Day';
} */
</style>