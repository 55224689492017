<template>
    <div class="container">
        <div class="glide" id="carousel">
            <div class="glide__track" data-glide-el="track">
                <ul class="glide__slides">
                    <li class="glide__slide">
                        <figure class="image">
                            <img src="guitar_pics/IMG_20231211_141207_363.jpg" alt="issa guitar">
                        </figure>
                    </li>
                    <li class="glide__slide">
                        <figure class="image">
                            <img src="guitar_pics/IMG_20231211_141207_397.jpg" alt="issanother guitar">
                        </figure>
                    </li>
                    <li class="glide__slide">
                        <figure class="image">
                            <img src="guitar_pics/IMG_20231211_141207_416.jpg" alt="issathird guitar">
                        </figure>
                    </li>
                </ul>
            </div>
            <!-- <div class="glide__arrows" data-glide-el="controls">
                    <button class="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button>
                    <button class="glide__arrow glide__arrow--right" data-glide-dir=">">next</button>
                </div> -->
        </div>
    </div>
</template>

<script>
import Glide from '@glidejs/glide'

export default {
    mounted() {
        let glide = new Glide('#carousel', {
            type: 'carousel',
            autoplay: 3000,
            perView: 1,
            peek: 0
        });

        glide.mount()
    }
}
</script>