<script setup>
import { ref, computed } from 'vue'



import SideNav from './components/SideNav.vue'

import AboutPage from './components/AboutPage.vue'
import ContactPage from './components/ContactPage.vue'
import GalleryPage from './components/GalleryPage.vue'
import HomePage from './components/HomePage.vue'
import TestimonialsPage from './components/TestimonialsPage.vue'

const routes = {
  '/': HomePage,
  '/about': AboutPage,
  '/contact': ContactPage,
  '/gallery': GalleryPage,
  '/testimonials': TestimonialsPage
}

const currentPath = ref(window.location.hash)

window.addEventListener('hashchange', () => {
  currentPath.value = window.location.hash
})

const currentPage = computed(() => {
  return routes[currentPath.value.slice(1) || '/']
})
</script>

<template>
  <div class="columns">
    <div class="column is-one-fifth">
      <SideNav activePage="home" />
    </div>
    <div class="column">
      <component :is="currentPage" />
      <!-- <footer class="footer">
        footer text placeholder
      </footer> -->
    </div>
  </div>
</template>

<style>
body {
  background-color: #C5D6CD;
}
</style>
