import { createApp } from 'vue';
import App from './App.vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

library.add(faFacebook);
library.add(faInstagram);
library.add(faYoutube);

require('@/assets/main.scss');

const app = createApp(App);

app.mount('#app');
