<template>
    <p>
        At Shamrock Guitars we have been hand crafting acoustic guitars using the finest
        materials since 2019. To achieve the best possible sound we employ expert
        construction techniques and meticulous attention to detail. Each build is a
        collaborative effort with the customer, ensuring a one of a kind guitar that is
        truly their own.
    </p>
</template>

<script>
export default {}
</script>