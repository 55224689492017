<template>
    <div class="container-fluid">
        <div class="columns">
            <div class="column is-four-fifths">
                <div class="content has-text-centered">
                    <h1>Shamrock Guitars
                        <span class="icon">
                            <img src="logo.png" alt="Shamrock Guitars logo">
                        </span>
                    </h1>
                    <h3>Handcrafted Acoustic Guitars</h3>
                </div>
                <p>
                    At Shamrock Guitars we have been hand crafting acoustic guitars using the finest
                    materials since 2019. To achieve the best possible sound we employ expert
                    construction techniques and meticulous attention to detail. Each build is a
                    collaborative effort with the customer, ensuring a one of a kind guitar that is
                    truly their own.
                </p>
            </div>
        </div>
        <div class="columns is-gapless">
            <div class="column is-two-fifths">
                <figure class="image">
                    <img src="guitar_pics/IMG_1359.png" alt="guitar front view">
                </figure>
            </div>
            <div class="column is-two-fifths">
                <figure class="image">
                    <img src="guitar_pics/IMG_1358.png" alt="guitar rear view">
                </figure>
            </div>
        </div>
    </div>
</template>

<script>

export default {
}
</script>
